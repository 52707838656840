import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'dropdown-select',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgSelectModule, FormsModule],
  templateUrl: './dropdown-select.component.html',
})
export class DropdownSelectComponent {
  @Input() options: any[] = [];
  @Input() disabled?: boolean;
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = 'id';
  @Input() placeholder: string = 'Select an option';
  @Input() defaultValue: any;
  @Input() isMultiple: boolean = false;
  selectedItem: any;

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private cd: ChangeDetectorRef) {}

  onChange(selectedValue: any) {
    this.selectedItem = selectedValue;
    this.selectionChanged.emit(selectedValue);
  }

  compareWithFn = (o1: any, o2: any) =>
    o1 && o2 ? o1[this.bindValue] === o2[this.bindValue] : o1 === o2;

  clearSelected() {
    this.selectedItem = [];
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.defaultValue = this.options.find(
        (option) => option.id === this.defaultValue
      );
      this.selectedItem = [this.defaultValue][0];
    }

    // if (this.disabled) {
    //   this.options = this.options.map((option) => {
    //     option.disabled = true;
    //     return option;
    //   });
    // }
  }

  ngAfterViewInit() {
    if (this.defaultValue) {
      setTimeout(() => {
        this.selectionChanged.emit([this.defaultValue][0]);
      }, 0);
      this.cd.detectChanges();
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserState, useUserStore } from 'src/app/store/user.store';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { useNavigationStore as navStore } from 'src/app/store/navigation.store';
import { RequestHelpersService } from 'src/app/shared/services/request-helpers.service';
import {
  AnalyticsManagerService,
  Events,
} from 'src/app/shared/services/analytics-manager.service';
import { HasDatePassed, GetDaysUntil } from 'src/app/helpers/date-helpers';
import { ProductTypes } from 'src/app/constants/productTypes';

const RoutesTransparent = [
  'billing-plans',
  'downloads',
  'download-success',
  'payment/success',
  'linkedin-login',
  'session-expired',
];

@Component({
  selector: 'app-header',
  template: `
    <div
    *ngIf="!isMobile"
      role="banner"
      class="flex__center nav_wrapper"
      [ngStyle]="{
        background: transparent
          ? 'transparent'
          : 'linear-gradient(to left top, #0D4557, #001241)'
      }"
    >
      <div class="flex__row--space-between nav_content">
        <div *ngIf="activeRoute !== 'linkedin-login'" class="logo">
          <img
            class="pipa"
            src="assets/images/pipa-white.png"
            draggable="false"
            alt="pipa"
          />
        </div>
        <div class="flex__center">
          <app-button
            style="margin-right: 10px;"
            class="secondary"
            *ngIf="!isMobile && !user?.downloaded_macos_app && !transparent"
            label="Download app"
            toRoute="downloads"
            [imageSrc]="appleIcon"
          ></app-button>
          <app-button
            *ngIf="
              user?.downloaded_macos_app && runCampaignAllowed && !transparent
            "
            (click)="handlePressLaunchPipa()"
            style="border-width: 2px; margin-right: 10px;"
            [class]="showAuthenticate ? 'success' : 'secondary'"
            textVariant="s5"
            fullWidth="true"
            [label]="showAuthenticate ? 'Authenticate' : 'Run Campaigns'"
          ></app-button>
          <app-verify-phone *ngIf="!transparent"></app-verify-phone>
          <app-account-menu
            [userName]="userName"
            *ngIf="!transparent"
          ></app-account-menu>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userName: string;
  user: UserState;
  runCampaignAllowed: boolean;
  activeRoute: string;
  isMobile: boolean;
  transparent = false;
  appleIcon = 'assets/icons/apple-icon.svg';
  cookieExpiry: string;
  showAuthenticate: boolean = false;

  constructor(
    private requestHelpersService: RequestHelpersService,
    private userService: UserProfileService
  ) { }

  ngOnInit() {
    this.transparent = RoutesTransparent.includes(
      navStore.getState().currentPage
    );

    this.userService.getUserProfile()
    useUserStore.subscribe(
      (s) => s,
      (s) => {
        this.user = s;
        this.userName = s.full_name;
        if (s.product_id === ProductTypes.CLOUD) {
          const cookieExpired = HasDatePassed(s.cookie_expiry);
          const isWithin7Days = GetDaysUntil(s.cookie_expiry) < 7;
          if (s.cookie_expiry === null || cookieExpired || isWithin7Days) {
            this.showAuthenticate = true;
          }
        }
      }
    );

    navStore.subscribe(
      (s) => s.currentPage,
      (newActiveRoute) => {
        this.activeRoute = newActiveRoute;
        this.transparent = RoutesTransparent.includes(newActiveRoute);
      }
    );

    if (this.transparent || window.innerWidth <= 800) {
      this.runCampaignAllowed = false;
    } else {
      this.runCampaignAllowed = true;
    }

    this.checkViewport();
    window.addEventListener('resize', this.checkViewport.bind(this));


    if (this.transparent || this.isMobile) {
      this.runCampaignAllowed = false;
    } else {
      this.runCampaignAllowed = true;
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkViewport.bind(this));
  }

  checkViewport(): void {
    this.isMobile = window.innerWidth <= 1024; // Check if the screen is tablet-sized or smaller
  }

  handlePressLaunchPipa() {
    const jwt = this.requestHelpersService.getJwt();
    if (this.showAuthenticate) {
      AnalyticsManagerService.recordButtonClick(Events.authenticateOnDesktopClicked);
      // @ts-ignore
      window.location = `pipa-app://authorization=${jwt}&initialTask=run-authLogin`;
    } else {
      AnalyticsManagerService.recordButtonClick(Events.runCampaignClicked);
      // @ts-ignore
      window.location = `pipa-app://authorization=${jwt}&initialTask=run-campaign`;
    }
  }
}

import { createPipaStore } from './store.helpers';

interface AlertBannerState {
  isVisible: boolean;
  message: string;
  variant?: 'warning' | 'danger';
}

export const useAlertBannerStore = (() =>
  createPipaStore<AlertBannerState>((set, get) => ({
    isVisible: false,
    message: '',
    variant: 'warning',
    hide: () => set({ isVisible: false }),
  })))();

<ng-select
  ngDefaultControl
  [items]="options"
  bindLabel="{{ bindLabel }}"
  bindValue="{{ bindValue }}"
  placeholder="{{ placeholder }}"
  [(ngModel)]="selectedItem"
  [compareWith]="compareWithFn"
  [multiple]="isMultiple"
  (clear)="clearSelected()"
  (change)="onChange($event)"
  appendTo="body"
>
</ng-select>
<!-- <p>Selected item: {{ selectedItem.id }}</p> -->

import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './layout/header/header.component';
import { AccountMenuComponent } from './layout/header/account-menu/account-menu.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { NavCellComponent } from './layout/side-nav/nav-cell/nav-cell.component';
import { AuthEffects } from './auth/store/auth.effects';
import { CampaignEffects } from './campaigns/store/campaigns.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MessageTemplatesEffects } from './message-templates/store/message-templates.effects';
import { VerifyPhoneComponent } from './layout/header/verify-phone/verify-phone.component';
import { ToastComponent } from './shared/toast/toast.component';
import { ModalsModule } from './modals/modals.module';
import * as Sentry from '@sentry/angular-ivy';
import * as fromRoot from '../app/store/app.reducer';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideNavComponent,
    NavCellComponent,
    VerifyPhoneComponent,
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot(fromRoot.appReducer),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    FormsModule,
    CommonModule,
    ModalsModule,
    ToastComponent,
    AccountMenuComponent,
    BrowserAnimationsModule,
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      AuthEffects,
      CampaignEffects,
      MessageTemplatesEffects,
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
  ],
  providers: [
    Location,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

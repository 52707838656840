import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/core/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'downloads', loadChildren: () => import('./downloads/downloads.module').then(module => module.DownloadsModule) },
  { path: 'download-success', loadChildren: () => import('./downloads/download-success/download-success.module').then(module => module.DownloadSuccessModule) },
  { path: 'campaigns', loadChildren: () => import('./campaigns/campaigns.module').then(module => module.CampaignsModule) },
  { path: 'leads', canActivate: [AuthGuard], loadChildren: () => import('./leads/leads.module').then(module => module.LeadsModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule) },
  { path: 'linkedin-login', canActivate: [AuthGuard], loadChildren: () => import('./linkedin-auth/linkedin-auth.module').then(module => module.LinkedinAuthModule) },
  { path: 'message-templates', loadChildren: () => import('./message-templates/message-templates.module').then(module => module.MessageTemplatesModule) },
  { path: 'user-profile', loadChildren: () => import('./user-profile/user-profile.module').then(module => module.UserProfileModule) },
  { path: 'payment', loadChildren: () => import('./payment/payments.module').then(module => module.PaymentsModule) },
  { path: 'billing-plans', loadChildren: () => import('./billing-plans/billing-plans.module').then(module => module.BillingPlansModule) },
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => import('./dashboard/dashboard.module').then(module => module.DashboardModule) },
  { path: 'leads', loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule) },
  { path: 'session-expired', loadChildren: () => import('./session-expired/session-expired.module').then(m => m.SessionExpiredModule) },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { CampaignsService } from "../services/campaigns.api.service";

import * as CampaignsActions from './campaigns.actions';
import { useCampaignStore } from "src/app/store/campaigns.store";
import { Campaign } from "../campaigns.model";

@Injectable()
export class CampaignEffects {
  getCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.getCampaigns),
      exhaustMap(() =>
        this.campaignsService.getCampaigns().pipe(
          map(response => {
            return CampaignsActions.getCampaignsSuccess({ payload: response })
          }),
          catchError((error) => of(CampaignsActions.getCampaignsFailure({ error }))))
      )
    )
  );

  updateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.updateCampaign),
      exhaustMap((action) =>
        this.campaignsService.updateCampaign(action.campaign).pipe(
          map(response => {
            CampaignsActions.updateCampaignSuccess({ payload: response });
            return CampaignsActions.getCampaigns();
          }),
          catchError((error) => of(CampaignsActions.updateCampaignFailure({ error }))))
      )
    )
  );

  addCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.addCampaign),
      exhaustMap((action) =>
        this.campaignsService.addCampaign(action.campaign).pipe(
          map((response: Campaign) => {
            CampaignsActions.addCampaignSuccess({ payload: response })
            useCampaignStore.getState().setNewCampaign(response)
            return CampaignsActions.getCampaigns();
          }),
          catchError((error) => {
            useCampaignStore.setState({ newCampaignError: error, newCampaign: {} })
            return of(CampaignsActions.addCampaignFailure({ error }))
          }))
      )
    )
  );

  deleteCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.deleteCampaign),
      exhaustMap((action) =>
        this.campaignsService.deleteCampaign(action.id).pipe(
          map(response => {
            CampaignsActions.deleteCampaignSuccess({ payload: response })
            return CampaignsActions.getCampaigns();
          }),
          catchError((error) => of(CampaignsActions.deleteCampaignFailure({ error }))))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private campaignsService: CampaignsService,
  ) { }

}

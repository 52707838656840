import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Subscription } from 'rxjs';
import { Route, Routes, useNavigationStore as navStore } from 'src/app/store/navigation.store';

import * as fromRoot from '../../store/app.reducer';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  isMobile = false;
  activeRoute: Route = null
  routesHidden = ['billing-plans', 'downloads', 'download-success', 'payment/success', 'linkedin-login', 'session-expired']
  routes = Routes
  templateIcon = "/assets/icons/template.svg";
  templateActiveIcon = "/assets/icons/template-active.svg";
  campaignIcon = "/assets/icons/radar.svg";
  campaignActiveIcon = "/assets/icons/radar-active.svg";
  homeIcon = "/assets/icons/home.svg";
  homeActiveIcon = "/assets/icons/home-active.svg";
  leadsIcon = "/assets/icons/list.svg";
  leadsActiveIcon = "/assets/icons/list-active.svg";
  settingsIcon = "/assets/icons/settings.svg";
  settingsActiveIcon = "/assets/icons/settings-active.svg";

  isAuthenticated = false;
  private userSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.AppState>,
  ) {
  }

  ngOnInit(): void {
    this.activeRoute = navStore.getState().currentPage;
    this.userSub = this.store
      .select('auth')
      .pipe(map(authState => authState.user))
      .subscribe(user => {
        this.isAuthenticated = !!user;
      });

    navStore.subscribe(s => s.currentPage, newActiveRoute => {
      this.activeRoute = newActiveRoute
    })

    this.checkViewport();
    window.addEventListener('resize', this.checkViewport.bind(this));
  }

  checkViewport(): void {
    this.isMobile = window.innerWidth <= 1024; // Check if the screen is tablet-sized or smaller
  }


  navigate(path: string): void {
    this.router.navigate([path], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
    window.removeEventListener('resize', this.checkViewport.bind(this));
  }

}

import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  template: `
  <div [ngStyle]="{'margin-bottom': (useErrorMessage) ? 0 : '0.35rem'}">
    <label *ngIf="label" class="input-field-label  s5" for="{{ name }}">
      {{ label }}
      <app-tooltip [content]="tooltipText" [id]="targetElement">
          <img *ngIf="useTooltip" class="input-field--tooltip_icon" src="{{infoIcon}}" alt="tooltip" draggable="false" style="margin-left: 0.25rem; width: 14px;">
      </app-tooltip>
    </label>
    <input class="form-control input-field " [placeholder]="placeholder" (change)="onChange($event.target.value)" (blur)="onTouched()" [disabled]="disabled"
      [value]="value" name="{{ name }}" id="{{ id }}" type="{{ type }}">
    <span *ngIf="useErrorMessage" [ngStyle]="{'color': (isError) ? '#800000' : 'transparent'}" class="error-message">{{errorMessage}}</span>
  </div>
  `,
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent implements OnInit {
  @Input() name: string = '';
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() type: 'text' | 'email' | 'password' | 'search' = 'text';
  @Input() value;
  @Input() required: boolean = false;
  @Input() isError: boolean = false;
  @Input() useErrorMessage: boolean = true;
  @Input() errorMessage: string = 'This field is required';
  @Input() useTooltip: boolean = false;
  @Input() targetElement: string;
  @Input() tooltipText: string;
  infoIcon = 'assets/icons/info.svg';

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
  }

  onChange: any = () => { };
  onTouched = () => { };

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

}

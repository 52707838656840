
export const States = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Georgia USA",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
]

export const Regions = [
  "Alabama",
  "Greater Birmingham, Alabama Area",
  "Greater Huntsville, Alabama Area",
  "Greater Florence-Muscle Shoals, Alabama Area",
  "Alaska",
  "Arizona",
  "Phoenix, Arizona",
  "Arkansas",
  "Greater Fayetteville, AR Area", "California",
  "San Francisco, California, United States",
  "San Diego, California, United States",
  "Sacramento, California, United States",
  "Long Beach, California, United States",
  "Los Angeles, California, United States",
  "Colorado",
  "Denver Metropolitan Area",
  "Greater Colorado Springs Area",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Boca Raton, FL",
  "Miami-Fort Lauderdale Area",
  "West Palm Beach, Florida, United States",
  "Georgia",
  "Atlanta Metropolitan Area",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

export const RegionsWithId = [
  { label: "Alabama", value: "102240587" },
  { label: "Greater Birmingham, Alabama Area", value: "90000100" },
  { label: "Greater Huntsville, Alabama Area", value: "105142920" },
  { label: "Greater Florence-Muscle Shoals, Alabama Area", value: "90000265" },
  { label: "Alaska", value: "100290991" },
  { label: "Arizona", value: "106032500" },
  { label: "Phoenix, Arizona", value: "100219842" },
  { label: "Arkansas", value: "102790221" },
  { label: "Greater Fayetteville, AR Area", value: "90000258" },
  { label: "California", value: "102095887" },
  { label: "San Francisco, California, United States", value: "102277331" },
  { label: "San Diego, California, United States", value: "103918656" },
  { label: "Sacramento, California, United States", value: "101857797" },
  { label: "Long Beach, California, United States", value: "106417510" },
  { label: "Los Angeles, California, United States", value: "102448103" },
  { label: "Colorado", value: "105763813" },
  { label: "Denver Metropolitan Area", value: "90000034" },
  { label: "Greater Colorado Springs Area", value: "90000172" },
  { label: "Connecticut", value: "106914527" },
  { label: "Delaware", value: "105375497" },
  { label: "District Of Columbia", value: "101116121" },
  { label: "Florida", value: "101318387" },
  { label: "Boca Raton, FL", value: "103462227" },
  { label: "Miami-Fort Lauderdale Area", value: "90000056" },
  { label: "West Palm Beach, Florida, United States", value: "102574077" },
  { label: "Georgia", value: "103950076" },
  { label: "Atlanta Metropolitan Area", value: "90000052" },
  { label: "Hawaii", value: "105051999" },
  { label: "Idaho", value: "102560739" },
  { label: "Illinois", value: "101949407" },
  { label: "Indiana", value: "103336534" },
  { label: "Iowa", value: "103078544" },
  { label: "Kansas", value: "104403803" },
  { label: "Kentucky", value: "106470801" },
  { label: "Louisiana", value: "101822552" },
  { label: "Maine", value: "101102875" },
  { label: "Maryland", value: "100809221" },
  { label: "Massachusetts", value: "101098412" },
  { label: "Michigan", value: "103051080" },
  { label: "Minnesota", value: "101197782" },
  { label: "Mississippi", value: "106899551" },
  { label: "Missouri", value: "101486475" },
  { label: "Montana", value: "101758306" },
  { label: "Nebraska", value: "101197782" },
  { label: "Nevada", value: "101690912" },
  { label: "New Hampshire", value: "103532695" },
  { label: "New Jersey", value: "101651951" },
  { label: "New Mexico", value: "105048220" },
  { label: "New York", value: "105080838" },
  { label: "North Carolina", value: "103255397" },
  { label: "North Dakota", value: "104611396" },
  { label: "Ohio", value: "106981407" },
  { label: "Oklahoma", value: "101343299" },
  { label: "Oregon", value: "101685541" },
  { label: "Pennsylvania", value: "102986501" },
  { label: "Rhode Island", value: "104877241" },
  { label: "South Carolina", value: "102687171" },
  { label: "South Dakota", value: "100115110" },
  { label: "Tennessee", value: "104629187" },
  { label: "Texas", value: "102748797" },
  { label: "Utah", value: "104102239" },
  { label: "Vermont", value: "104453637" },
  { label: "Virginia", value: "101630962" },
  { label: "Washington", value: "103977389" },
  { label: "West Virginia", value: "106420769" },
  { label: "Wisconsin", value: "104454774" },
  { label: "Wyoming", value: "100658004" }
];

export const campaignDailyLimitPercentages = [
  { id: 0.0, name: '0%' },
  { id: 0.01, name: '1%' },
  { id: 0.02, name: '2%' },
  { id: 0.03, name: '3%' },
  { id: 0.04, name: '4%' },
  { id: 0.05, name: '5%' },
  { id: 0.06, name: '6%' },
  { id: 0.07, name: '7%' },
  { id: 0.08, name: '8%' },
  { id: 0.09, name: '9%' },
  { id: 0.1, name: '10%' },
  { id: 0.11, name: '11%' },
  { id: 0.12, name: '12%' },
  { id: 0.13, name: '13%' },
  { id: 0.14, name: '14%' },
  { id: 0.15, name: '15%' },
  { id: 0.16, name: '16%' },
  { id: 0.17, name: '17%' },
  { id: 0.18, name: '18%' },
  { id: 0.19, name: '19%' },
  { id: 0.2, name: '20%' },
  { id: 0.21, name: '21%' },
  { id: 0.22, name: '22%' },
  { id: 0.23, name: '23%' },
  { id: 0.24, name: '24%' },
  { id: 0.25, name: '25%' },
  { id: 0.26, name: '26%' },
  { id: 0.27, name: '27%' },
  { id: 0.28, name: '28%' },
  { id: 0.29, name: '29%' },
  { id: 0.3, name: '30%' },
  { id: 0.31, name: '31%' },
  { id: 0.32, name: '32%' },
  { id: 0.33, name: '33%' },
  { id: 0.34, name: '34%' },
  { id: 0.35, name: '35%' },
  { id: 0.36, name: '36%' },
  { id: 0.37, name: '37%' },
  { id: 0.38, name: '38%' },
  { id: 0.39, name: '39%' },
  { id: 0.4, name: '40%' },
  { id: 0.41, name: '41%' },
  { id: 0.42, name: '42%' },
  { id: 0.43, name: '43%' },
  { id: 0.44, name: '44%' },
  { id: 0.45, name: '45%' },
  { id: 0.46, name: '46%' },
  { id: 0.47, name: '47%' },
  { id: 0.48, name: '48%' },
  { id: 0.49, name: '49%' },
  { id: 0.5, name: '50%' },
  { id: 0.51, name: '51%' },
  { id: 0.52, name: '52%' },
  { id: 0.53, name: '53%' },
  { id: 0.54, name: '54%' },
  { id: 0.55, name: '55%' },
  { id: 0.56, name: '56%' },
  { id: 0.57, name: '57%' },
  { id: 0.58, name: '58%' },
  { id: 0.59, name: '59%' },
  { id: 0.6, name: '60%' },
  { id: 0.61, name: '61%' },
  { id: 0.62, name: '62%' },
  { id: 0.63, name: '63%' },
  { id: 0.64, name: '64%' },
  { id: 0.65, name: '65%' },
  { id: 0.66, name: '66%' },
  { id: 0.67, name: '67%' },
  { id: 0.68, name: '68%' },
  { id: 0.69, name: '69%' },
  { id: 0.7, name: '70%' },
  { id: 0.71, name: '71%' },
  { id: 0.72, name: '72%' },
  { id: 0.73, name: '73%' },
  { id: 0.74, name: '74%' },
  { id: 0.75, name: '75%' },
  { id: 0.76, name: '76%' },
  { id: 0.77, name: '77%' },
  { id: 0.78, name: '78%' },
  { id: 0.79, name: '79%' },
  { id: 0.8, name: '80%' },
  { id: 0.81, name: '81%' },
  { id: 0.82, name: '82%' },
  { id: 0.83, name: '83%' },
  { id: 0.84, name: '84%' },
  { id: 0.85, name: '85%' },
  { id: 0.86, name: '86%' },
  { id: 0.87, name: '87%' },
  { id: 0.88, name: '88%' },
  { id: 0.89, name: '89%' },
  { id: 0.9, name: '90%' },
  { id: 0.91, name: '91%' },
  { id: 0.92, name: '92%' },
  { id: 0.93, name: '93%' },
  { id: 0.94, name: '94%' },
  { id: 0.95, name: '95%' },
  { id: 0.96, name: '96%' },
  { id: 0.97, name: '97%' },
  { id: 0.98, name: '98%' },
  { id: 0.99, name: '99%' },
  { id: 1, name: '100%' },
];

<div class="selectable-profile-cell" (click)="onSelect()">
  <div class="profile-content">
    <input
      *ngIf="!isLoading"
      type="checkbox"
      class="select-checkbox"
      id="select-checkbox"
      [checked]="isSelected && selectable"
      [disabled]="!selectable"
    />
    <ng-container
      *skeleton="
        isLoading;
        repeat: 1;
        className: 'mb-2';
        height: '40px';
        width: '40px';
        borderRadius: '50%'
      "
    >
      <profile-image
        [fullName]="result.lead_name"
        [image]="result.image_url"
      ></profile-image>
    </ng-container>
    <div class="profile-details">
      <ng-container
        *skeleton="
          isLoading;
          repeat: 3;
          className: 'mb-2';
          height: '20px';
          width: 'rand';
          borderRadius: '10px'
        "
      >
        <div (click)="$event.stopPropagation()" class="name">
          <a [href]="result.profile_url" target="_blank" class="profile-link">{{
            result.lead_name
          }}</a
          ><span *ngIf="distance" class="distance number"
            >· {{ distance }}</span
          >
        </div>
        <div class="jobtitle details-text">{{ result.job_title }}</div>
        <div class="location details-text">{{ result.location }}</div>

        <ng-container *ngIf="result?.leadListId || result?.campaignId">
          <div class="constraint-indicator-wrapper">
            <div
              *ngIf="result?.leadListId"
              class="constraint-indicator lead-list-indicator"
              [title]="result?.leadListName"
            >
              <img
                src="assets/icons/list-icon.svg"
                alt="lead list"
                class="constraint-indicator-icon"
              />
              <span
                [ngClass]="{
                  'single-indicator': !result?.leadListId || !result?.campaignId
                }"
                class="constraint-name"
                >{{ result?.leadListName }}</span
              >
            </div>
            <div
              *ngIf="result?.campaignId"
              class="constraint-indicator campaign-indicator"
              [title]="result?.campaignName"
            >
              <img
                src="assets/icons/radar-active.svg"
                alt="campaign"
                class="constraint-indicator-icon"
              />
              <span
                [ngClass]="{
                  'single-indicator': !result?.leadListId || !result?.campaignId
                }"
                class="constraint-name"
                >{{ result?.campaignName }}</span
              >
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <!-- <a [href]="result.profile_url" target="_blank" class="profile-link"
    >View Profile</a
  > -->
</div>

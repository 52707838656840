import { Component, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  AnalyticsManagerService,
  Events,
} from '../services/analytics-manager.service';

@Component({
  selector: 'app-button',
  template: `
    <button
      [disabled]="disabled"
      [id]="id"
      [name]="name"
      class="app_button {{ textVariant || 's5' }} {{ class }} {{
        fullWidth ? 'full-width-button' : ''
      }}"
      (click)="toRoute ? navigate() : null"
      [style]="style"
    >
      {{ !isLoading ? label : loadingLabel }}
      <img
        *ngIf="!!imageSrc"
        src="{{ imageSrc }}"
        draggable="false"
        [alt]="name"
        class="apple-icon"
        style="height: 15px; width: 15px; {{ imgStyle && imgStyle }}"
      />
      <div
        *ngIf="isLoading"
        class="spinner-border spinner-border-sm"
        role="status"
        style="margin: 0 6px 0 6px; color: {{ fadedPurple }};"
      ></div>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
})
export class AppButtonComponent {
  @Input() textVariant?: string;
  @Input() style?: any;
  @Input() fullWidth: boolean;
  @Input() id: string;
  @Input() name: string;
  @Input() buttonConfig: any;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() toRoute: string;
  @Input() imageSrc: any;
  @Input() imgStyle: any;
  @Input() isLoading: boolean;
  @Input() loadingLabel: string = '';
  @Input() isKeyUpEnter: boolean;
  @Input() keyUpEnter: EventEmitter<any>;
  @Input() class:
    | 'primary'
    | 'secondary'
    | 'text'
    | 'dismiss'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'light'
    | 'dark'
    | 'link'
    | 'outlined'
    | 'bright'
    | 'opaque' = 'primary';
  fadedPurple = '#4b53bcac';

  constructor(private router: Router) { }

  navigate(): void {
    if (this.toRoute === 'downloads') {
      AnalyticsManagerService.recordButtonClick(Events.downloadAppClicked);
    }
    this.router.navigate([this.toRoute]);
  }

  onKeyUpEnter(): void {
    if (this.isKeyUpEnter) {
      this.keyUpEnter.emit();
    }
  }
}

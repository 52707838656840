import { ActionReducerMap } from '@ngrx/store';

import * as fromAuth from '../auth/store/auth.reducer';
import * as fromCampaigns from '../campaigns/store/campaigns.reducer';
import * as fromMessageTemplates from '../message-templates/store/message-templates.reducer';

export interface AppState {
  auth: fromAuth.State;
  campaigns: fromCampaigns.State;
  messageTemplates: fromMessageTemplates.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  campaigns: fromCampaigns.campaignsReducer,
  messageTemplates: fromMessageTemplates.messageTemplatesReducer
}

<app-modal [visible]="isVisible" [usePadding]="false">
  <div class="modal-wrapper flex__column">
    <div class="modal-header flex__center--space-between">
      <h5 class="modal-title">Campaign Daily Connection Limit</h5>
      <div class="flex__center">
        <!-- <app-button
          class="light"
          [label]="'Weight Evenly'"
          (click)="evenlyDistributeWeights()"
        >
        </app-button> -->
        <app-button
          [disabled]="+totalCampaignsAllocatedPercentage > 1"
          (click)="onSaveClick()"
          [label]="'Save'"
        >
        </app-button>
        <img
          (click)="onCloseModalClick()"
          class="_close-icon close-icon"
          src="assets/icons/x-close-black.svg"
          alt="X"
          draggable="false"
        />
      </div>
    </div>
    <div class="help-content">
      <span class="daily-limit-prompt help-block"
        >Assign a weight for each campaign</span
      >
      <app-tooltip
        [content]="dailyLimitTooltip"
        [id]="'dailyLimitTooltip'"
        [tooltipPosition]="'right'"
      >
        <img
          class="info-icon"
          src="assets/icons/info.svg"
          alt="tooltip"
          draggable="false"
          style="margin-left: 0.5rem; width: 16px"
        />
      </app-tooltip>
    </div>
    <div
      class="flex__row--space-between campaign-dropdown-wrapper"
      *ngFor="let campaign of campaigns"
    >
      <div class="flex__row--space-between campaign-switch-wrapper">
        <span class="campaign-nickname">{{ campaign.campaign_nickname }}</span>
        <app-switch
          id="campaign-switch"
          title="Toggle campaign on/off"
          (click)="onToggleCampaign($event, campaign.id, !campaign.enabled)"
          [checked]="campaign.enabled"
          size="medium"
        >
        </app-switch>
      </div>
      <dropdown-select
        [options]="options"
        [defaultValue]="setDefaultPercentage(campaign.id)"
        [placeholder]="dropdownPlaceholder"
        (selectionChanged)="onItemSelect($event, campaign.id)"
      >
      </dropdown-select>
    </div>
    <div class="daily-limit-breakdown-wrapper">
      <span class="pipa-help-text flex__row--end">{{
        setWarningText(totalCampaignsAllocatedPercentage)
      }}</span>
      <div class="flex__row--end">
        <span
          *ngIf="!!totalCampaignsAllocatedPercentage"
          class="percentage-total-label"
          >Total:
        </span>
        <span
          class="percentage-total-text"
          [ngStyle]="{
            color: +totalCampaignsAllocatedPercentage > 1 ? 'red' : '#4B53BC'
          }"
        >
          {{
            totalCampaignsAllocatedPercentage &&
              formatToPercentage(+totalCampaignsAllocatedPercentage)
          }}</span
        >
      </div>
    </div>
  </div>
</app-modal>
